import { OfflineModeSyncResponse } from './offline-mode-sync-response.interface';
import { Observable } from 'rxjs';
import { TcOfflineModeStates } from './tc-offline-mode-states.enum';

export interface TcOfflineModeService {
  syncPercentage$?: Observable<number>;
  offlineModeState?: TcOfflineModeStates;

  hasOfflineMode(): Promise<boolean>;
  activateOfflineMode(): Promise<OfflineModeSyncResponse>;
  terminateOfflineMode(): Promise<OfflineModeSyncResponse>;
}
