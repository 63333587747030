import { TcSmartButton } from '@tc/buttons';
import { AgGridEvent, GridReadyEvent } from 'ag-grid-community';

export interface TcGridSort {
  key: string;
  order: string;
}

export interface TcGridButtonParams {
  label?: string;
  icon?: string;
  tooltip?: string;
  tooltipClass?: string;
  disabled?: (node) => boolean;
  hidden?: (node) => boolean;
  action: (...args: any[]) => any;
}

export interface TcGridAutocompleteOption {
  value: any;
  label: string;
}

export interface TcGridEventButtonParams extends TcSmartButton {
  disabled?: (node) => boolean;
  hidden?: (node) => boolean;
  action: (...args: any[]) => any;
}

export interface TcGridSmartButtonParams extends TcGridEventButtonParams {
  actionPayload?: any;
}

export interface TcGridReadyEvent extends GridReadyEvent {
  refresh: () => void;
}
export interface TcGridLoadedDataEvent {
  data: any[];
}
