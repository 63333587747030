import {
  BaseTcStorePayload,
  TcFilterDef,
  TcSmartListConfig,
  TcSmartComponentConfig,
  TcSortDef,
  BaseCallbackTcStorePayload,
} from '@tc/abstract';

/**
 * init tc data payload
 */
export interface InitTcDataPayload {
  configs: TcSmartComponentConfig[];
}

/**
 * init tc data payload
 */
export interface InitTcListDataPayload extends BaseCallbackTcStorePayload {
  // dataProvider: TcDataProviderConfig;
  // take: number;
  // skip: number;
  // filterConfig?: TcFilterConfig;
  listConfig: TcSmartListConfig;
}

/**
 * load tc data payload
 */
export interface LoadTcDataPayload extends BaseTcStorePayload {
  skip?: number;
  take?: number;
  filter?: TcFilterDef;
  sort?: TcSortDef;
}

/**
 * Filters on tc data payload
 */
export interface FiltersTcDataPayload extends BaseTcStorePayload {
  filter?: TcFilterDef;
}

export interface SortTcDataPayload extends BaseTcStorePayload {
  sort?: TcSortDef;
}

/**
 * load tc data payload success
 */
export interface LoadTcDataPayloadSuccess<T extends object = object>
  extends BaseTcStorePayload {
  data: T[];
  total: number;
  take: number;
  skip: number;
  filter?: TcFilterDef;
  sort?: TcSortDef;
  createdOn?: string;
}

/**
 * load tc more data payload
 */
export interface LoadTcMoreDataPayload extends LoadTcDataPayload {}

/**
 * load tc more data success payload
 */
export interface LoadTcMoreDataPayloadSuccess
  extends LoadTcDataPayloadSuccess {}

/**
 *  refresh tc data
 */
export interface RefreshTcDataPayload extends BaseTcStorePayload {
  filter: TcFilterDef;
}

/**
 *  filter tc data success
 */
export interface RefreshTcDataSuccessPayload<T extends object = object>
  extends BaseTcStorePayload {
  filter: TcFilterDef;
}

/**
 * payload for saving to db
 */
export interface CreateItemPayload extends BaseTcStorePayload {
  item: any;
}

/**
 *  payload for saving to store
 */
export interface CreateItemSuccessPayload extends BaseTcStorePayload {
  item: any;
}

/**
 * payload for saving to db
 */
export interface UpdateItemPayload extends CreateItemPayload {
  displaySuccessNotification?: boolean;
}

/**
 *  payload for saving to store
 */
export interface UpdateItemSuccessPayload extends CreateItemSuccessPayload {}

/**
 * payload for deleting to db
 */
export interface DeleteItemPayload extends CreateItemPayload {}

/**
 * payload for deleting to store
 */
export interface DeleteItemSuccessPayload extends CreateItemSuccessPayload {}

/**
 * payload for updating tc data total in store
 */
export interface LoadTcDataTotalSuccessPayload extends BaseTcStorePayload {
  total: number;
}
