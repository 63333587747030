import { TcConfig } from './tc-config';
import { TcDataProviderType } from './tc-data-services.enum';
import { TcFilterDef } from './tc-filter.interfaces';
import { TcSortDef } from './tc-sort.interfaces';
import { DataType } from 'breeze-client';

/**
 * Configuration of TcDataProvider
 */
export interface TcDataProviderConfig extends TcConfig {
  providerType: TcDataProviderType;

  dataSet: string;

  /**
   * Whether the data source is from a child property of an existing model inside the store
   */
  fromParentModelProperty?: {
    /**
     * The store key linked to the parent form that we want to get the child property from
     */
    formStoreKey: string;
    /**
     * The path to the property from which we want to get the data from
     */
    propertyPath: string;
  };

  /**
   * A comma-separated (',') string of property paths or an array of property paths
   */
  fields?: string | string[];

  /**
   *  Definition of filters to apply on data set
   */
  filter?: TcFilterDef;

  /*
   * Initial sort
   */
  sortOrder?: TcSortDef;

  /**
   * Distinct/group by capability
   * Gets distinct values of the field specified in the fields property
   */
  distinct?: boolean;

  /**
   * Splits the GET Data call into 2 calls:
   * 1 - for getting the data
   * 2 - for getting the inlineCount
   *
   * Setting this to true drastically improves the performance when working with Mongo views as dataSets
   */
  separatedInlineCountCall?: boolean;

  /**
   * Transforms the result obtained from the execution of the query
   */
  transformFn?: (queryResult: any) => any;

  /**
   * Config to create a dynamic collection from an array to breeze local cache
   */
  dynamicCollectionFrom?: {
    breezeStructuralType: string; // Path to the data to find related metadata
    data: any[] | (() => Promise<any[]>); // Data to put into the collection. Can be an static array or a method returning dynamical data. With big data set, use method. Writing a lot of data in the store is bad for performances.
    /**
     * If defined, will add the properties of the breezeStructuralTypeExtension to the new dataset that we create.
     * For each property that we provide, we can assign its breeze datatype to it
     * Eg: { newProperty1: 'String', newProperty2: 'Double' }
     */
    breezeStructuralTypeExtension?: { [propertyName: string]: DataType };
  };
}
