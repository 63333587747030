import { TcPermissionsConfig } from './tc-permissions-config';

/**
 * tc grid add button config
 */
export interface TcGridAddButtonConfig extends TcPermissionsConfig {
  /**
   * true if add button will be hidden
   */
  hide?: boolean;

  /**
   * material icon
   */
  matIcon?: string;

  /**
   * font awesome icon
   */
  faIcon?: string;

  /**
   * Ionicons icon
   */
  ionIcon?: string;

  /**
   * if a component is specified, when click on add button, a popup will be displayed with that component
   * the component should has the TcComponentLookup decorator applied
   */
  detailsPopupComponent?: string;

  /**
   * The width of the displayed popup
   */
  detailsPopupWidth?: string;

  /**
   * The height of the displayed popup
   */
  detailsPopupHeight?: string;

  /**
   * The default value of the model for every new item
   */
  defaultModel?: any;

  /**
   * The action that is dispatched when the add button is cliked
   */
  action: any;

  /**
   * true if an secondary add button will be displayed
   * by default it appears in the top-right corner of the grid, to
   * customise it's position/size use css in the custom app.
   * css selector: .tc-grid-secondary-add-button
   */
  showSecondaryAddButton?: boolean;
}
