import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { TcGridAddButtonConfig } from './tc-grid-add-button-config';
import { TcPinnedRowsConfig } from './tc-grid-pinned-rows-config';
import { TcSmartListConfig } from './tc-smart-list.config';

export interface ColDefExtended extends ColDef {
  priority?: number;
}

export interface ColGroupDefExtended extends ColGroupDef {
  priority?: number;
}

export interface ColumnNumberPerDevice {
  extraSmallDevice?: number;
  smallDevice?: number;
  mediumDevice?: number;
  largeDevice?: number;
  extraLargeDevice?: number;
  extraExtraLargeDevice?: number;
}

/**
 * tc grid config
 */
export interface TcSmartGridConfig extends TcSmartListConfig {
  /**
   * tc grid options
   */
  gridOptions: GridOptions;

  /**
   * tc grid columns
   */
  columns: (ColDefExtended | ColGroupDefExtended)[];

  /**
   * tc grid infinite scroll percent (vertical scroll percent when the grid will trigger load more data event). by default 75
   */
  infiniteScrollPercent?: number;

  /**
   * css class
   */
  cssClass?: string;

  /**
   * add button config
   */
  addButton?: TcGridAddButtonConfig;

  /**
   * mass update
   */
  massUpdate?: any; // TODO Task 66711

  /**
   * an object containing the amount of columns to display on each device based on priority
   */
  columnNumberPerDevice?: ColumnNumberPerDevice;

  /**
   * empties data in store when destroying component
   */
  emptyDataOnDestroy?: boolean;

  /**
   * pinned rows configuration
   */
  pinnedRowsConfig?: TcPinnedRowsConfig[];

  /**
   * Rows to pre-select when the grid has loaded. Must contain either a 'id' or '_id' property
   */
  selectedRows?: any[];
}
